<template>
  <div>
    <van-list
      v-model:loading="loading"
      :finished="sallList"
      v-model:error="slip"
      :loading-text="t('nft.issuance.loading')"
      :finished-text="t('nft.issuance.noMore')"
      :error-text="t('nft.issuance.requestFailed')"
      @load="onLoad"
    >
      <ul class="sale" @click="cancel">
        <li v-for="item in ownerNFTs" :key="item.id">
          <header>
            <img
              :src="item.image ? item.image : errorImg"
              @error.once="imgerror"
            />
          </header>
          <h4>{{ item.name }}</h4>
          <div>
            <span style="opacity: 0.5">{{ $t('nft.market.price') }}</span>
            <span style="color: #e1ab01">{{ checkPrice(item.price) }}KNT</span>
          </div>
          <p>
            {{ item.describe }}
          </p>
          <button
            :data-tokenid="item.tokenId"
            :data-id="item.saleId"
            :data-price="item.price"
          >
            {{ $t('nft.personal.personalSale.cancelTheSale') }}
          </button>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router'
  import { reactive, ref, toRef, toRefs, computed } from 'vue'
  import {
    buffNFTs,
    cancelSales,
    nftSaleEntities,
    getCatMetadata,
    precision,
  } from '@/api/nft.js'
  import { Toast, List } from 'vant'
  import { useStore } from 'vuex'
  import { formatAmount } from '@/utils/format.js'
  import { useI18n } from 'vue-i18n'
  import ipfs from '@/utils/ipfs.js'
  export default {
    name: 'PersonalSale',
    setup() {
      const { t } = useI18n()
      const router = useRouter()
      const store = useStore()
      let data = reactive({
        ownerNFTs: [],
        accuricy: 18,
      })
      let skip = 0
      let page = -1
      let loading = ref(false)
      let sallList = computed(() => store.state.sallList)
      let slip = ref(false)
      store.commit('CHANGESALE', false)
      iniData()
      //获取列表数据
      async function iniData() {
        data.accuricy = await precision()
        page++
        skip = page * 4
        try {
          let resultData = (await nftSaleEntities(skip, 4)).data.nftSaleEntities
          let result = [
            ...resultData.filter(
              (item) =>
                item.status == 0 &&
                item.seller == window.ethereum.selectedAddress
            ),
          ]
          result = await Promise.all(
            result.map(async (item) => {
              let data = await getCatMetadata(item.tokenId)
              let name = data.metadata.name
              let image = ipfs(data.metadata.image)
              let describe = data.metadata.describe
              return { ...item, name, image, describe }
            })
          )
          data.ownerNFTs.push(...result)
          loading.value = false
          if (resultData.length < 4) store.commit('CHANGESALE', true)
        } catch (error) {
          console.log(error)
          slip.value = true
        }
      }
      let checkPrice = (price) => {
        let p = Number(formatAmount(price, data.accuricy))
        if (p.toString().split('.')[1]?.length > 6) {
          return p.toFixed(6)
        }
        return p
      }
      //取消正在售卖的nft
      let cancel = async (e) => {
        if (e.target.tagName.toLowerCase() == 'button') {
          router.push({
            name: 'ProductDescription',
            query: {
              id: e.target.dataset.id,
              seller: window.ethereum.selectedAddress,
              tokenid: e.target.dataset.tokenid,
              price: e.target.dataset.price,
              identify: 'market',
              cancel: 'cancel',
            },
          })
          let tokenid = e.target.dataset.tokenid
          let index = data.ownerNFTs.findIndex(
            (item) => item.tokenId == tokenid
          )
          data.ownerNFTs.splice(index, index + 1)
        }
      }
      let onLoad = () => {
        iniData()
      }
      let errorImg = require('@/assets/error.jpg')
      let imgerror = (e) => {
        e.target.src = errorImg
      }
      return {
        ...toRefs(data),
        cancel,
        loading,
        sallList,
        slip,
        onLoad,
        imgerror,
        errorImg,
        checkPrice,
        t,
      }
    },
    components: { [List.name]: List },
  }
</script>

<style lang="scss" scoped>
  .sale {
    width: 100%;
    padding: 0.3846rem;

    li {
      padding: 0.5128rem;
      width: 100%;
      color: #fff;
      background: linear-gradient(to bottom, #202945, #161e34);
      border-radius: 0.3077rem;
      margin-bottom: 0.3846rem;

      header {
        width: 100%;
        margin-bottom: 0.2051rem;

        img {
          width: 100%;
          border-radius: 0.3077rem;
        }
      }

      h4 {
        font-size: 0.5128rem;
      }

      div {
        display: flex;
        justify-content: space-between;
        margin-top: 0.0769rem;

        span {
          font-size: 0.359rem;
        }
      }

      p {
        opacity: 0.4;
        margin-top: 0.2564rem;
        width: 100%;
        height: 1.7949rem;
        font-size: 0.359rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-height: 0.641rem;
      }

      button {
        margin-top: 0.359rem;
        width: 4.1026rem;
        height: 1.0256rem;
        background-color: #d12521;
        border-radius: 0.5128rem;
        font-size: 0.4103rem;
      }
    }
  }

  .toast {
    text-align: center;
    opacity: 0.5;
    color: #fff;
  }
</style>
