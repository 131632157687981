<template>
  <div>
    <van-list
      v-model:loading="loading"
      :finished="heldList"
      v-model:error="slip"
      :loading-text="t('nft.issuance.loading')"
      :finished-text="t('nft.issuance.noMore')"
      :error-text="t('nft.issuance.requestFailed')"
      @load="onLoad"
    >
      <ul class="held" @click="sell">
        <li v-for="item in ownerNFTs" :key="item.tokenID">
          <header>
            <img
              :src="item.image ? item.image : errorImg"
              @error.once="imgerror"
            />
          </header>
          <h4>{{ $t('nft.issuance.nftName') }}:{{ item.name }}</h4>
          <p>
            {{ item.describe }}
          </p>
          <button
            :data-token="item.tokenID"
            :data-name="item.name"
            :data-owner="item.owner"
          >
            {{ $t('nft.personal.personalHeld.sell') }}
          </button>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
  import ipfs from '@/utils/ipfs.js'
  import { Dialog, Toast, List } from 'vant'
  import { useRouter } from 'vue-router'
  import { reactive, ref, toRefs, computed, onMounted } from 'vue'
  import {
    buffNFTs,
    startSales,
    approveTokenid,
    getCatMetadata,
    precision,
  } from '@/api/nft.js'
  import { useStore } from 'vuex'
  import { formatAmount } from '@/utils/format.js'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'PersonalHeld',
    setup(props) {
      const router = useRouter()
      const store = useStore()
      const { t } = useI18n()
      let data = reactive({
        ownerNFTs: [],
        nftName: '',
      })
      let skip = 0
      let page = -1
      let loading = ref(false)
      let heldList = computed(() => store.state.heldList)
      let slip = ref(false)
      store.commit('CHANGDEHELD', false)
      iniData()
      async function iniData() {
        page++
        skip = page * 4
        try {
          let resultData = (await buffNFTs(skip, 4)).data.ownerNFTs
          let result = [...resultData.filter((item) => item.status == 2)]
          result = await Promise.all(
            result.map(async (item) => {
              let data = await getCatMetadata(item.tokenID)
              let describe = data.metadata.describe
              let name = data.metadata.name
              let image = ipfs(data.metadata.image)
              return { ...item, name, image, describe }
            })
          )
          data.ownerNFTs.push(...result)
          loading.value = false
          if (resultData.length < 4) store.commit('CHANGDEHELD', true)
        } catch (error) {
          slip.value = true
        }
      }

      let nftPrice = ref(null)
      let seller = ref(null)
      //出售
      let tokenID = ref()
      let sell = async (e) => {
        if (e.target.tagName.toLowerCase() == 'button') {
          router.push({
            name: 'ProductDescription',
            query: {
              tokenid: e.target.dataset.token,
              seller: e.target.dataset.owner,
              identify: 'held',
            },
          })
        }
      }

      let onLoad = () => {
        iniData()
      }
      onMounted(() => {})
      let errorImg = require('@/assets/error.jpg')
      let imgerror = (e) => {
        e.target.src = errorImg
      }
      return {
        sell,
        close,
        ...toRefs(data),
        nftPrice,
        loading,
        heldList,
        slip,
        onLoad,
        errorImg,
        imgerror,
        t,
      }
    },
    components: {
      [Dialog.Component.name]: Dialog.Component,
      [List.name]: List,
    },
  }
</script>

<style lang="scss" scoped>
  .held {
    width: 100%;
    padding: 0.3846rem;

    li {
      padding: 0.5128rem;
      width: 100%;
      color: #fff;
      background: linear-gradient(to bottom, #202945, #161e34);
      border-radius: 0.3077rem;
      margin-bottom: 0.3846rem;

      header {
        width: 100%;
        margin-bottom: 0.2051rem;

        img {
          width: 100%;
          border-radius: 0.3077rem;
        }
      }

      h4 {
        font-size: 0.5128rem;
      }

      div {
        display: flex;
        justify-content: space-between;
        width: 3.8462rem;
        margin-top: 0.0769rem;

        span {
          font-size: 0.359rem;
        }
      }

      p {
        opacity: 0.4;
        margin-top: 0.2564rem;
        width: 100%;
        height: 1.7949rem;
        line-height: 0.5641rem;
        font-size: 0.359rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }

      button {
        margin-top: 0.359rem;
        width: 4.1026rem;
        height: 1.0256rem;
        background-color: #d12521;
        border-radius: 0.5128rem;
        font-size: 0.4103rem;
      }
    }
  }

  .popups {
    height: 7.1795rem;
    background: linear-gradient(to top, #202945, #161e34);
    color: #fff;
    padding: 0.5128rem;

    .title {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 0.4615rem;
      font-weight: 700;
    }

    .nftName {
      text-align: center;
      margin-top: 0.8205rem;
      font-size: 0.5128rem;
    }

    .ipt {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5128rem;
      height: 1.2821rem;
      font-size: 0.5128rem;
      border: 0.0256rem solid #899ac9;
      border-radius: 0.2051rem;
      padding-left: 0.1282rem;

      input {
        text-align: center;
        flex: 3;
        width: 70%;
        border: 0;
        outline: none;
        background-color: transparent;
      }

      span {
        flex: 1;
      }
    }

    .close {
      width: 0.4103rem;
      height: 0.4103rem;
      position: absolute;
      right: 0.3077rem;
      top: 0.3077rem;
      background: url('./images/close@2x.png') center no-repeat;
      background-size: 0.4103rem;
    }

    .btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 0.8205rem;
      width: 100%;

      button {
        height: 1.0256rem;
        width: 2.8718rem;
        border-radius: 0.5128rem;
        background-color: #313748;
        color: #fff;
        font-size: 0.4103rem;
      }

      :nth-child(2) {
        background: linear-gradient(to right, #ffd25e, #d28500);
      }
    }
  }

  .toast {
    text-align: center;
    opacity: 0.5;
    color: #fff;
  }
</style>
