<template>
  <div class="content">
    <div class="nftImg"></div>
    <div class="ntfBtn">
      <button :class="isShow ? 'pick' : ''" @click="held">
        {{ $t('nft.personal.main.personalHeld') }}
      </button>
      <button :class="isShow ? '' : 'pick'" @click="sale">
        {{ $t('nft.personal.main.personalSale') }}
      </button>
    </div>
    <div class="mian">
      <PersonalHeld v-if="isShow"></PersonalHeld>
      <PersonalSale v-if="!isShow"></PersonalSale>
    </div>
  </div>
</template>

<script>
import PersonalSale from './PersonalSale'
import PersonalHeld from './PersonalHeld'
import { reactive, ref } from 'vue'
export default {
  name: 'Personal',
  setup() {
    let isShow = ref(true)
    let held = async () => {
      isShow.value = true
    }
    let sale = () => {
      isShow.value = false
    }
    return {
      isShow,
      held,
      sale,
    }
  },
  components: {
    PersonalSale,
    PersonalHeld,
  },
}
</script>

<style lang="scss" scoped>
.content {
  min-height: 100vh;

  .nftImg {
    width: 100%;
    height: 7.6923rem;
    background: url('./images/bg@2x.png') top no-repeat;
    background-size: 100%;
  }

  .ntfBtn {
    display: flex;
    width: 9.2308rem;
    height: 1.0256rem;
    margin: 0 auto;
    border-radius: 0.3077rem;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #92959c;
      background: #292f3e;
      font-size: 0.4103rem;
    }

    :nth-child(1) {
      border-radius: 0.2051rem 0 0 0.2051rem;
    }

    :nth-child(2) {
      border-radius: 0 0.2051rem 0.2051rem 0;
    }

    .pick {
      background: linear-gradient(to right, #d28500, #ffd25e);
      color: #fff;
    }
  }
}
</style>
